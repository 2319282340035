import React,{useEffect}  from 'react'
import map from './images/map.png';
import $ from 'jquery';
function Contact() {

  useEffect(() =>
  {
  $("#myparallax").css("opacity",".5");
  })


  
//test()
  return (
    <div style={{position:'absolute' , left:'20px'}}>
      <br/><br/>
      <b>Contact information:</b><br/>

      <b>&nbsp; address:</b><br/>500 Geller Rd. Wilmer, TX 75172<br/><br/>
      <b>&nbsp; phone:</b><br/>972-220-9186<br/><br/>
      <b>&nbsp; email:</b><br/><a href="mailto:'info@dfwpit.vom'">info@dfwpit.com</a>
      
      <br/><br/><center>
      <a target="_blank" href="https://www.google.com/maps/place/500+Geller+Rd,+Wilmer,+TX+75172/@32.6518509,-96.7733465,11z/data=!4m6!3m5!1s0x864eb923941cbe91:0xd3387f9420c929e4!8m2!3d32.595077!4d-96.6467289!16s%2Fg%2F11cs6nzjqc?entry=ttu"><img src={map} style={{width:'80%'}} /></a>
      </center>
      </div>
  )
}

export default Contact