import React,{useEffect}  from 'react'
import $ from 'jquery';
import p1 from './images/Top-soil.jpg';
import p2 from './images/Select-Fill.jpg';
import p3 from './images/Screened-Loam.jpg';
import p4 from './images/Plaster-Sand-a.jpg';
import p5 from './images/Plaster-Sand.jpg';
import p6 from './images/Mason-Sand.jpg';
import p7 from './images/Loam-Soil.jpg';
import p8 from './images/cushion-sand.jpg';
import p9 from './images/concrete-sand.jpg';
import p10 from './images/Common-Fill-a.jpg';
import p11 from './images/Common-Fill.jpg';
import p12 from './images/Rocks--1-or-1.5-inch-sizes-.jpg';
import p13 from './images/Rocks--1-or-1.5-inch-sizes.jpg';
import './Css/styles.css';

function Product() {

 
  
//test()
  return (
    <div>
      <br/><center>
      <div className="container">
  <div className="item">
    <img src={p1}   alt="Image 1"/>Top-soil
  </div>
  <div className="item">
    <img src={p2}   alt="Image 2"/>Select-Fill
  </div>
  <div className="item">
    <img src={p3}   alt="Image 2"/>Screened Loam
  </div> 
  <div className="item">
    <img src={p4}   alt="Image 2"/>Plaster Sand-a
  </div>   

  <div className="item">
    <img src={p5}   alt="Image 2"/>Plaster Sand
  </div> 
  <div className="item">
    <img src={p6}   alt="Image 2"/>Mason Sand
  </div> 
  <div className="item">
    <img src={p7}   alt="Image 2"/>Loam Soil
  </div> 
  <div className="item">
    <img src={p8}   alt="Image 2"/>cushion-sand
  </div>   
   <div className="item">
    <img src={p9}   alt="Image 2"/>concrete-sand
  </div> 
     <div className="item">
    <img src={p10}   alt="Image 2"/>Common Fill-a
  </div> 
    <div className="item">
    <img src={p11}   alt="Image 2"/>Common Fill
  </div>   
    <div className="item">
    <img src={p12}   alt="Image 2"/>Rocks 1 inch
  </div>   
    <div className="item">
    <img src={p13}   alt="Image 2"/>Rocks 1.5 inch
  </div>   
  </div> </center>
  </div>  
  )
}

export default Product