import React, { useState, useEffect,createContext } from "react";
import { NavLink, Link, useLocation  } from "react-router-dom";
import logo from './Temp-Logo.png';
import './Css/App2.css';
export const Appnewsletter=createContext();

const Navbar = ({Deano}) => {
  let savedCustomer_username=localStorage.getItem('Customer_username')
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [SHow_Newsletter, setSHow_Newsletter]=useState(true)
  const [isSlide, setisSlide] = useState(false);
  const [Login_name, setLogin_name]=useState('Name');
 
  //console.log("Navbar")
  const toggleVisibility = (e) => {
    Deano("on");
  };
  const closeMenu = (e) =>
  { setOpen(false)}
  const closeDropdown = () => { setDropdownVisible(false);};
 
  useEffect(() =>
  {
  },[])
 
  return (
    <div>
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    > <div class="containerlogo" style={{height:'140px'}}><img src={logo}  className="logo_main"  style={{width:'900px'}}/></div>
      <div className="container">
        <div className="navbar-brand">
        <div class="containerlogo" style={{height:'50px'}} ><img src={logo} style={{width:'50%'}}   className="logo_small"  /></div>
          <a
            role="button"
            className={`navbar-burger burger ${isOpen && "is-active"}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setOpen(!isOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isOpen && "is-active"}`}>
          <div style={{alignContent:'left', textAlign:'left'}} className="navbar-start" onClick={(e) => closeMenu(e)}>
         
            <NavLink  className="navbar-item" to="./home"  >
              Home
            </NavLink>
            
            {/* <NavLink className="navbar-item  has-dropdown is-hoverable" */}
            <NavLink  className="navbar-item"        
               to='./contact' activeClassName="is-active"  >  
              Contact
            </NavLink>

            <NavLink className="navbar-item" activeClassName="is-active"   to="/estimate">
             Estimate
            </NavLink>    

            <NavLink className="navbar-item" activeClassName="is-active"   to="/product">
             Product
            </NavLink>          
         </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <button  className='banner_button2' onClick={(e) => toggleVisibility(e)}>News Letter</button>
             </div>
          </div>
        </div>
        </div> 
    </nav>
    
    </div>
  );
};

export default Navbar;