import React, {useState, useEffect, useContext, createContext} from 'react'
import axios from 'axios'


function APIcall({Getcred, response_data}) {


useEffect(() =>
{ 
  //alert(`http://kustom-jewelry.com/DFW_sand_register.aspx?email=${send_data}`)
    const klmT=axios.get(`http://kustom-jewelry.com/DFW_sand_register.aspx?email=${Getcred}`)
      .then(response => {
        let ApiR1 = response.data;
        console.log("API Sent data  " + FormData.uid);
      })
      .then(ApiR1 => {
        console.log("done");
        response_data("done")
      })
      .catch(error => { // Handle error if necessary
      })
 
},[])
 
}

export default React.memo(APIcall)