import React,{useState,useEffect} from 'react'
import sand_piln from './images/Sand_pil.jpg'
function Estimate() {
    const [Estimate_info,setEstimate_info]=useState({
        Full_Name:'',
        Phone_Number:'',
        Email_Address:'',
        Delivery_Address:'',
        Detailed_Information:''
      })
  return (
    <div>
        <br/>
        <div className='parent_estimate'>

            <div className='dives1'></div>
            <div className='dives2'></div>
            <div className='dives3'>
            Use the form below and provide all the information about your project needs<br/><br/>
                <label>Full Name:</label><br/>
                <input name="Full_Name" size='35' type='text'></input><br/>
                <label>Phone Number:</label><br/>
                <input name="Phone_Number" size='35' type='text'></input><br/> 
                <label>Email Address:</label><br/>
                <input name="Email_Address" size='35' type='text'></input><br/>                 
                <label>Delivery Address:</label><br/>
                <input name="Delivery_Address" size='35' type='text'></input><br/>  
                <label>Detailed Information:</label><br/>
                <textarea name="Detailed_Information" cols={38} rows={10} type='text'></textarea><br/>   
                <button  className='banner_button'>Submit</button>                                            
            </div>       
            <div className='dives4'>
                <img src={sand_piln} style={{width:'100%'}}/><br/><br/>
                <b>Select Your Sand & Gravel</b><br/>
                Select Sand & Gravel is available to provide estimates or to discuss material 
                needs for construction and other project types.  We provide material delivery 
                of Sand, Gravel, Rock, Topsoil, Road Base, and Fill Dirt via Dump Truck or 
                End-Dump Trucking.  We haul to locations in Dallas, Fort Worth, Austin, 
                Houston, San Antonio, Texas, and Oklahoma City.  Contact us for a Free Estimate 
                or to schedule delivery.
            </div>                  
        </div>
        <br/><br/>
    </div>
  )
}

export default Estimate