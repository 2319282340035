import { Parallax, ParallaxLayer } from '@react-spring/parallax'

import React,{useEffect,useState, useContext} from 'react'

import './Css/App.css';
import './Css/App2.css';

import './Css/styles.css';
import './Css/fadins.css';
import {BrowserRouter as Router,Routes,Route, Switch,Navigate,Link} from "react-router-dom";
import { BrowserRouter } from 'react-router-dom' 
import Navbar from './Navbar'

import Contact from './Contact'
import Footer from './Footer'
import Banner from './images/banner.png';
import s1 from './images/s1.jpg';
import s2 from './images/s2.jpg';
import s3 from './images/s3.jpg';
import p1 from './images/Top-soil.jpg';
import p2 from './images/Select-Fill.jpg';
import p3 from './images/Screened-Loam.jpg';
import p4 from './images/Plaster-Sand-a.jpg';
import p5 from './images/Plaster-Sand.jpg';
import p6 from './images/Mason-Sand.jpg';
import p7 from './images/Loam-Soil.jpg';
import p8 from './images/cushion-sand.jpg';
import p9 from './images/concrete-sand.jpg';
import p10 from './images/Common-Fill-a.jpg';
import p11 from './images/Common-Fill.jpg';
import p12 from './images/Rocks--1-or-1.5-inch-sizes-.jpg';
import p13 from './images/Rocks--1-or-1.5-inch-sizes.jpg';
import axios from 'axios';
import $ from 'jquery'
function Home() {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);  
  const [isVisible_box1, setIsVisible_box1] = useState(false);   
  const [isVisible_box2, setIsVisible_box2] = useState(false);  
  const [isVisible_box3, setIsVisible_box3] = useState(false);   
  const [page_height, setpage_height] = useState(2); 
  const [the_height, setthe_height]=useState(0);
  const [the_width, setthe_width]=useState(0);  
  const goto_estimate =(e) =>
  {
    window.location.href = '/#/estimate'
  }
  useEffect(() => {
    function change_resize()
    {
      setthe_height(window.innerHeight)
      setthe_width(((window.innerWidth)/900).toFixed(2))
      let klm_height=window.innerHeight
      let klm_width=((window.innerWidth)/1200).toFixed(2)    
      let parallax_height=((780/klm_height).toFixed(2))*2
     // console.log(window.innerWidth, "  " ,klm_width )
      if (window.innerWidth >900 ) {
      setpage_height((parallax_height + (klm_width/7)).toFixed(2));
      }
     //console.log("resize") 
    }
    $("#myparallax").css("opacity","1");
    console.log("useEffect")
    setIsVisible(true);
    setTimeout(function() {setIsVisible2(true)},1400);
    let mywrapper=document.getElementById("myparallax")

    let scroll_mywrapper= mywrapper.addEventListener('scroll', function() {
      setTimeout(function() {setIsVisible_box1(true)},500);
      setTimeout(function() {setIsVisible_box2(true)},800);  
      setTimeout(function() {setIsVisible_box3(true)},1200); 
      ;
      console.log("scroll")             
    });
   
    let resize_mywrapper=  window.addEventListener('resize', change_resize)
    localStorage.setItem('DFWsand', "sessionid")
    setTimeout(function()
    {
      localStorage.removeItem('DFWsand')
    },1500)
   
    return () => {
      window.removeEventListener('resize', change_resize)
      mywrapper.removeEventListener('scroll', scroll_mywrapper)
    };
  }, []); 
  return (
    <div>
      <Parallax id="myparallax" key={page_height} pages={page_height} style={{ top: '0', left: '0' }} className="animation">
        <ParallaxLayer style={{zIndex:'10', alignContent:'left'}} offset={0} speed={0.25}>
        <div id='main22' style={{zIndex:'200'}} className='parent_james'>
            <div className='div1'>
            </div>
            <div className='div2'>
            </div> 
            <div className='div3'>
            <div className={`fade-in_box1 ${isVisible2 ? 'visible' : ''}`}>
                <img src={Banner} className='banner_text'/><br/>
                {/* <button className='banner_button'>-{page_height}-  &nbsp;  {the_height} &nbsp;  {the_width}</button> */}
                <button onClick={(e) => goto_estimate(e)} className='banner_button'>Get an Estimate</button>
                </div>
            </div>    
          </div>
      
        </ParallaxLayer>        
        <ParallaxLayer offset={0} speed={0.25}>
          <div className="animation_layer parallax" id="artback"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.45}>
          <div className="animation_layer parallax" id="mountain"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.25}>
        <center>
        <div className='parent_james2'>
   
            <div className='div22' >
            <div className={`fade-in_box1 ${isVisible_box1 ? 'visible' : ''}`}>
              <img src={s1} className='img_sample'/> <br/>
              <b>Common Fill</b><br/>
              
            </div> 
            </div>        
          <div className='div32'>
            <div className={`fade-in_box1 ${isVisible_box2 ? 'visible' : ''}`}>
            <img src={s2} className='img_sample' /> <br/>
              <b>concrete-sand</b><br/>
              
            </div> 
          </div>           
          <div className='div42'>
            <div className={`fade-in_box1 ${isVisible_box3 ? 'visible' : ''}`}>
              <img src={s3} className='img_sample' /> <br/>
              <b>cushion-sand</b> <br/>
              
            </div>    
          </div>   <br/>
                    
        </div>  <br/><br/>
       
        <div style={{alignContent:'center', textAlign:'center', justifyContent: 'center', display:'flex'}}>
        <div id="textblock">
        <center><center>
      <div className="container">
  <div className="item">
    <img src={p1}   alt="Image 1"/>Top-soil
  </div>
  <div className="item">
    <img src={p2}   alt="Image 2"/>Select-Fill
  </div>
  <div className="item">
    <img src={p3}   alt="Image 2"/>Screened Loam
  </div> 
 

  <div className="item">
    <img src={p5}   alt="Image 2"/>Plaster Sand
  </div> 
  <div className="item">
    <img src={p6}   alt="Image 2"/>Mason Sand
  </div> 
  <div className="item">
    <img src={p7}   alt="Image 2"/>Loam Soil
  </div> 
  <div className="item">
    <img src={p8}   alt="Image 2"/>cushion-sand
  </div>   
   <div className="item">
    <img src={p9}   alt="Image 2"/>concrete-sand
  </div> 
 
    <div className="item">
    <img src={p11}   alt="Image 2"/>Common Fill
  </div>   
    <div className="item">
    <img src={p12}   alt="Image 2"/>Rocks 1 inch
  </div>   
    <div className="item">
    <img src={p13}   alt="Image 2"/>Rocks 1.5 inch
  </div>   
  </div> </center>
          </center>
        </div>
        </div>
        </center>
        </ParallaxLayer>        
        <ParallaxLayer offset={0} speed={0.5}>
          <div className="animation_layer parallax" id="jungle3"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.45}>
          <div className="animation_layer parallax" id="jungle4"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.40}>
          <div className="animation_layer parallax" id="manonmountain"></div>
        </ParallaxLayer>
      </Parallax>
   
   </div>
  );
}

export default Home;
