import React,{useState,useEffect} from 'react'
import {
  BrowserRouter as Router, Link,
  useLocation
} from "react-router-dom";

function Footer() {
  const [scrollStat,setscrollStat]=useState(false)
  const [hasscrollStat,sethasscrollStat]=useState(false)
  
  //console.log(" The Location is : ")
 
 
  return (
    <div  className="bottom-component">
     
        <table width={'100%'}><thead></thead><tbody><tr><td align='left' valign='top' width='50%'>
        
        Copyright ©2024  DFW Pit Inc, All Rights Reserved
          
            </td><td>&nbsp;</td><td>   
            <div style={{display:'flex'}}>
             500 Geller  Rd. Wilmer, TX  75172    &nbsp; (Phone: 972-220-9186)
          </div>
          </td><td>
          </td></tr></tbody>
      </table>
       </div>
  )
}

export default Footer