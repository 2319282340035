import './App.css';
import s1 from './images/s1.jpg';
import s2 from './images/s2.jpg';
import s3 from './images/s3.jpg';
function TextBlock() {
    return (
        <div id="textblock">
        <center><br/>
          itaque repudiandae molestiae, nobis sequi incidunt autem! Cum impedit accusamus laboriosam eaque necessitatibus consectetur<br/>
            consectetur, itaque maxime sit iusto aliquam culpa reiciendis eaque recusandae vitae modi, animi expedita perferendis 
          inventore. Culpa incidunt odit dicta quibusdam ullam facilis eum magnam, consectetur voluptates praesentium, pariatur 
          eos nihil! Natus in, autem aut facere repellat eveniet commodi illo ullam hic fugit deserunt explicabo esse atque 
          reprehenderit optio, dicta suscipit sed! Porro fugiat numquam vel magni, iure provident consequuntur velit ut quia
          <br/>
          itaque repudiandae molestiae, nobis sequi incidunt autem! Cum impedit accusamus laboriosam eaque necessitatibus consectetur<br/>
            consectetur, itaque maxime sit iusto aliquam culpa reiciendis eaque recusandae vitae modi, animi expedita perferendis 
          inventore. Culpa incidunt odit dicta quibusdam ullam facilis eum magnam, consectetur voluptates praesentium, pariatur 
          eos nihil! Natus in, autem aut facere repellat eveniet commodi illo ullam hic fugit deserunt explicabo esse atque 
          reprehenderit optio, dicta suscipit sed! Porro fugiat numquam vel magni, iure provident consequuntur velit ut quia 
          <hr width='50%' style={{backgroundColor:'#BBBBBB', alignSelf:'center'}}/>     
          
          <div className='services'>
            <b>Services for Sand & Gravel Delivery include:</b><br/>
              <ul>
                <li>Needs analysis for Crushed Stone, Gravel, Topsoil, Dirt, or Sand.</li>
                <li>Fast Service for ongoing Sand & Gravel projects and customers.</li>
                <li>Customized Hauling for Rock, Dirt, Topsoil, Gravel, or Sand.</li>
                <li>Calculation of Quantity - Sand, Gravel, Rock, Fill Dirt requirements.</li>
              </ul> 
          </div>
          </center>
        </div>
    );
}

export default TextBlock;