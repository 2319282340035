import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import TextBlock from './textBlock';
import React,{useEffect,useState, useContext} from 'react'

import './Css/App.css';
import './Css/App2.css';
import './Css/bulma2.css';
import './Css/styles.css';
import './Css/fadins.css';
import mountain from'./images/Mountain1.jpg'
import truck from'./images/Trucks.png'
import {BrowserRouter as Router,Routes,Route, Switch,Navigate,Link,useLocation} from "react-router-dom";
import { BrowserRouter } from 'react-router-dom' 
import Navbar from './Navbar';

import { createBrowserHistory } from 'history';

import Contact from './Contact'
import Footer from './Footer'
import Home from './Home'
import Estimate from './Estimate';
import Product from './Product';
import APIcall from './APIcall'
import Banner from './images/banner.png';
import s1 from './images/s1.jpg';
import s2 from './images/s2.jpg';
import s3 from './images/s3.jpg';
import close_button from './images/close_button.png';
import axios from 'axios';
import $ from 'jquery'
function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [Send_newletter, setSend_newletter] = useState(false);  
  const [send_data, setsend_data] = useState(null);   
  const [email_textbox, setemail_textbox] = useState(null);  
  const [thankyou_msg, setthankyou_msg] = useState(false);  
  const [theloader,settheloader]=useState(true)
  
const get_newsletter_stat = (data) =>
{ if (data=="on")  setIsVisible(true)}
const close_newsletter = (e) =>
{ setIsVisible(false); e.preventDefault()}
const send_email_newsletter = (e) =>
{
  setsend_data(email_textbox)
  setSend_newletter(true)
}
const collect_email =(e) =>
{  setemail_textbox(e.target.value)}
 
const response_fromAPI =(data) =>
{
  if (data=="done") { 
    setthankyou_msg(true);
    setTimeout(function() {setIsVisible(false)},3000)
  }
}
  useEffect(() => {
    let currentPageUrl = window.location.href;
    let currentPageUrl2=currentPageUrl.split("/#/");
    let currentPageUrl3=currentPageUrl2[1]
    //console.log(currentPageUrl2, "    " ,currentPageUrl, "    " ,currentPageUrl3)
 console.log(" The Location is ::: " + currentPageUrl3)
 if (currentPageUrl3 =="home" || currentPageUrl3 =="contact" || currentPageUrl3 =="estimate") 
 {settheloader(false); }
    const get_cart_history=localStorage.getItem('DFWsand')
    if (get_cart_history ) {
    } else {
  
    if (currentPageUrl3 !="estimate" && currentPageUrl3 !="contact") {
    const timeoutId = setTimeout(() => {
    window.location.href = '/#/home';
    settheloader(false)
    },1600);}
  }
  }, [window.location.href]); 
  return (
    <div>
    <BrowserRouter  basename="/#" >
       <Navbar   Deano={get_newsletter_stat} /> 
       {isVisible && 
      (
        !thankyou_msg ? 
        <div className='newsletter'>
        <input type='text' onChange={(e) => collect_email(e)}/>&nbsp;
        <button className='banner_button2' onClick={(e) => send_email_newsletter(e)}>Submit</button>&nbsp;
        <a href="" onClick={(e) => close_newsletter(e)}><img src={close_button} alt="Close" title="Close"  width='20px'/></a></div>
        : 
        <div  className='newsletter'>
          Thank you for your subscribtion!
        </div>
      )
    }
     {Send_newletter && <APIcall Getcred={send_data} response_data={response_fromAPI}  />}
     <br/><br/>
     <>{theloader && ( <div className='spain_container'><div  className={'loader1'}></div></div>)}</>
    <Switch>
        <Route path="/contact">
          <Contact />
        </Route>  
        <Route path="/home">
          <Home />
        </Route>  
        <Route path="/estimate">
          <Estimate />
        </Route>    
        <Route path="/product">
          <Product />
        </Route>                                           
      </Switch>
         <Footer /> 
         
    </BrowserRouter>
     <img src={mountain} width='1px'/><img src={truck} width='1px'/>
    </div>
  );
}

export default App;
